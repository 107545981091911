@keyframes :global(ripple) {
  0% {
    top: calc(50% - var(--ripple-size) / 2);
    left: calc(50% - var(--ripple-size) / 2);
    width: var(--ripple-size);
    height: var(--ripple-size);
    border-color: var(--ripple-color);
  }
  90% {
    border-color: var(--ripple-color);
  }
  98% {
    border-color: transparent;
  }
  100% {
    top: calc(50% - var(--ripple-size) * var(--count) / 2);
    left: calc(50% - var(--ripple-size) * var(--count) / 2);
    width: calc(var(--ripple-size) * var(--count));
    height: calc(var(--ripple-size) * var(--count));
  }
}

@mixin ripple-for($count, $delay, $global-delay) {
  $frame-duration: $count * $delay;

  @for $index from 1 through $count {
    .ripple-frame-#{$index} {
      $frame-delay: $global-delay + $delay * $index;
      animation: ripple $frame-duration $frame-delay linear infinite;

      @each $increment in (0 10 20 30) {
        @if $index == $increment + 1 {
          opacity: 0.4;
        }

        @if $index == $increment + 2 {
          opacity: 0.5;
        }

        @if $index == $increment + 3 {
          opacity: 0.6;
          border-width: 2px;
        }

        @if $index == $increment + 4 {
          opacity: 0.8;
          border-width: 3px;
        }

        @if $index == $increment + 5 {
          opacity: 1;
          border-width: 4px;
        }

        @if $index == $increment + 6 {
          opacity: 0.8;
          border-width: 2px;
        }

        @if $index == $increment + 7 {
          opacity: 0.6;
        }

        @if $index == $increment + 8 {
          opacity: 0.5;
        }

        @if $index == $increment + 9 {
          opacity: 0.4;
        }

        @if $index == $increment + 10 {
          opacity: 0.3;
        }
      }
    }
  }
}

:global {
  .ripple-frame {
    position: absolute;
    border: 1px solid transparent;
    border-radius: 50%;
  }

  @each $size in (14 11 7) {
    @each $delay in (0s 2s 4s 6s) {
      .ripple-#{$size}-#{$delay} {
        --count: #{$size};
        @include ripple-for($size, 0.6s, $delay);
      }
    }
  }
}
