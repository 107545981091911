@import '../../../../../../styles/shared';

.subsectionsOffset {
  margin-top: 24px;

  @include mq($from: desktop) {
    margin-top: 56px;
  }
}

.link {
  display: inline-block;
  padding: 0 0 6px 0;
  border-radius: 0;
  background: none;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  min-height: auto;
  color: var(--hub-column-text-module-color);

  &::before {
    display: none;
  }
}

.itemGridWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 12px 12px;
  height: 100%;
  padding-bottom: 10px;
  background-color: var(--hub-column-text-module-item-background-color);
  color: var(--hub-column-text-module-color);
  border: 1px solid var(--hub-column-text-module-item-border-color);
}

.itemBody {
  padding: 15px;

  @include mq($from: desktop) {
    padding: 20px;
  }
}

.itemBottomOffset {
  margin-bottom: 24px;
}

.iconHolder {
  display: block;
  padding: 0;
  width: var(--hub-column-text-module-icon-width);
  min-height: var(--hub-column-text-module-icon-height);
  margin: 12px;
  border-radius: 0;

  @include mq($from: desktop) {
    margin: 24px 24px 12px;
  }
}

.imageHolder {
  display: block;
  max-width: 100%;
  min-height: 250px;
}

.imageSizeFit {
  background-size: contain;
}

.imageSizeFill {
  background-size: cover;
}

.imagePositionCenter {
  background-position: center;
}

.imagePositionLeft {
  background-position: left;
}

.imagePositionRight {
  background-position: right;
}

.itemHeading {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;

  @include mq($from: desktop) {
    font-size: 28px;
    line-height: 42px;
  }
}

.itemParagraph {
  font-size: 16px;
  line-height: 24px;
  padding-top: 8px;

  @include mq($from: desktop) {
    font-size: 16px;
    line-height: 32px;
  }
}

.itemFooter {
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: 12px;

  @include mq($from: desktop) {
    padding: 20px 20px 12px;
  }
}

.ctaButtonOffset {
  margin-top: 0;

  @include mq($from: desktop) {
    margin-top: 34px;
  }
}

@include hub-dark-theme() {
  .itemLink {
    @extend .link;
    border-bottom: 2px solid
      var(--hub-column-text-module-dark-item-cta-border-color);
  }
}

@include hub-light-theme() {
  .itemLink {
    @extend .link;
    border-bottom: 2px solid
      var(--hub-column-text-module-light-item-cta-border-color);
  }
}

.lightNoBorder {
  .itemGridWrapper {
    padding-bottom: 0;
    border: 0;
    border-radius: 0;
  }

  .imageHolder {
    border-radius: 12px;
  }

  .itemBody,
  .itemFooter {
    padding-left: 0;
    padding-right: 0;
  }

  .itemFooter {
    padding-bottom: 0;
  }

  .itemLink {
    @extend .link;
    border-bottom: 2px solid
      var(--hub-column-text-module-light-item-cta-border-color);
  }
}

.brandLight {
  background-color: var(--hub-column-text-module-brand-light-background-color);
  color: var(--hub-column-text-module-brand-light-color);

  .itemLink {
    @extend .link;
  }

  .itemGridWrapper {
    border: none;
  }
}

.brandDark {
  background-color: var(--hub-column-text-module-brand-dark-background-color);
  color: var(--hub-column-text-module-brand-dark-color);

  .itemLink {
    @extend .link;
  }

  .itemGridWrapper {
    border: none;
  }
}

.turtles {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.turtle {
  position: absolute;
}

.donatello {
  bottom: 300px;
  left: 160px;
}

.michelangelo {
  bottom: 260px;
  left: 833px;
}

.raphael {
  bottom: 395px;
  left: 1365px;
}

.leonardo {
  top: 380px;
  right: 115px;
}
