@import '../../../../../../../styles/shared';

.offsetBottom {
  margin-bottom: 24px;
}

.content {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--hub-column-text-module-item-background-color);
  color: var(--hub-column-text-module-color);
  border: 1px solid var(--hub-column-text-module-item-border-color);
  border-radius: 12px 12px;
  height: 100%;
  padding: 12px;

  @include mq($from: tablet) {
    padding: 20px;
  }
}

.imageHolder {
  display: block;
  width: 100%;
  min-height: 150px;
  background-size: contain;
  margin: 12px 0;

  @include mq($from: tablet) {
    width: 170px;
    margin: auto 0 auto 12px;
  }
}

.body {
  display: flex;
  flex-direction: column;

  @include mq($from: tablet) {
    flex-direction: row;
  }
}

.textWrapper {
  flex: 1;
}

.link {
  display: inline-block;
  padding: 0 0 6px 0;
  border-radius: 0;
  background: none;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  min-height: auto;
  color: var(--hub-column-text-module-color);

  &::before {
    display: none;
  }
}

.heading {
  font-size: 20px;
  line-height: 42px;
  font-weight: 700;

  @include mq($from: desktop) {
    font-size: 28px;
  }
}

.paragraph {
  font-size: 16px;
  line-height: 24px;
  padding-top: 8px;

  @include mq($from: desktop) {
    font-size: 16px;
    line-height: 32px;
  }
}

.footer {
  padding: 20px 0 0 0;
}

@include hub-dark-theme() {
  .itemLink {
    @extend .link;
    border-bottom: 2px solid
      var(--hub-column-text-module-dark-item-cta-border-color);
  }
}

@include hub-light-theme() {
  .itemLink {
    @extend .link;
    border-bottom: 2px solid
      var(--hub-column-text-module-light-item-cta-border-color);
  }
}

:global(.hub-module-brand-light-theme) {
  background-color: var(--hub-column-text-module-brand-light-background-color);
  color: var(--hub-column-text-module-brand-light-color);

  .itemLink {
    @extend .link;
  }

  .content {
    border: none;
  }
}

:global(.hub-module-brand-dark-theme) {
  background-color: var(--hub-column-text-module-brand-dark-background-color);
  color: var(--hub-column-text-module-brand-dark-color);

  .itemLink {
    @extend .link;
  }

  .content {
    border: none;
  }
}
